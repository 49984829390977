import React from "react";
import { Link } from "react-scroll";
import { Parallax } from "react-parallax";
import { scrollDuration } from "../config/commonConfig";



const CallToAction = () => {
  return (
    <section>
      <div className="hero-wrap">
        <div className="hero-mask opacity-8 bg-dark" />
        <Parallax
          bgImage="images/intro-bg-2.jpg"
          bgImageAlt="Intro"
          strength={200}
        >
          <div className="hero-content section">
            <div className="container text-center py-5 wow fadeInUp">
              <h2 className="text-10 fw-600 text-white mb-5">
                Interested in working with me?
              </h2>

              <br/>
              {/* <div className="stage-cube-cont">
                <div className="cubespinner">
                  <div className="face1">
                    <FontAwesomeIcon icon={faLaravel} color="#DD0031" />
                  </div>
                  <div className="face2">
                    <FontAwesomeIcon icon={faPhp} color="#F06529" />
                  </div>
                  <div className="face3">
                    <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
                  </div>
                  <div className="face4">
                    <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
                  </div>
                  <div className="face5">
                    <FontAwesomeIcon icon={faLinux} color="#EFD81D" />
                  </div>
                  <div className="face6">
                    <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
                  </div>
                </div>
              </div> */}
              <br/>

              <Link
                className="btn btn-primary rounded-0 smooth-scroll wow rubberBand"
                data-wow-delay="1s"
                smooth="easeInOutQuint"
                duration={scrollDuration}
                to="contact"
              >
                Hire Me!
              </Link>{" "}
            </div>
          </div>
        </Parallax>
      </div>
    </section>
    
  );
};

export default CallToAction;
