import "./index.scss";
import AnimatedLetters from "../AnimatedLetters";
import { useEffect, useState } from "react";
// import { Link } from 'react-router-dom'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
import Loader from "react-loaders";

const Layout = () => {
  const [letterClass, setLetterClass] = useState("text-animate");
  const nameArray = ["C", "a", "r", "l", "o", "s", ","];
  const jobArray = [
    "F",
    "u",
    "l",
    "l",
    " ",
    "S",
    "t",
    "a",
    "c",
    "k",
    " ",
    "D",
    "e",
    "v",
    "e",
    "l",
    "o",
    "p",
    "e",
    "r",
  ];

  useEffect(() => {
    setTimeout(() => {
      clearTimeout();
      setLetterClass("text-animate-hover");
    }, 4000);
  }, []);

  return (
    <div className="page">
      {/* here to put the component for animation */}
      <div className="container home-page">
        
        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,</span>
            <br />
            <span className={`${letterClass} _13`}>I</span>
            <span className={`${letterClass} _14`}>'m</span>
            <span className={`${letterClass} _14`}>&nbsp;</span>
            {/* <img src={LogoTitle} alt="developer" /> */}

            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={15}
            />
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={jobArray}
              idx={30}
            />
          </h1>

          <h2>
            {/* Web Developer / PHP / React / Next.Js / Big Data Analyst  */}
            <Typewriter
              options={{
                strings: [
                  "Web Developer",
                  "PHP",
                  "React",
                  "Next.js",
                  "Big Data Analyst - Toronto Metropolitan University",
                  "Bachelor’s Degree in Computer Science",
                  "Oracle PL/SQL Certified Associate",
                  "Certificate in Web Design - Humber College",
                  "E-Commerce and Internet Development - University of Toronto",
                  "Mobile Developer Certificate - Ryerson University",
                ],
                autoStart: true,
                loop: true,
              }}
            />{" "}
          </h2>
          <Router>
            <Link to="/contact" className="flat-button">
              CONTACT ME
            </Link>
          </Router>
        </div>
        
      </div>
    </div>
  );
};
export default Layout;
